var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chat-style-comment-linked-blog-answer" }, [
    _c(
      "span",
      {
        staticClass: "chat-style-comment-linked-blog-answer__text",
        class: {
          "chat-style-comment-linked-blog-answer__text_white":
            _vm.moveAvatarToRight,
        },
      },
      [
        _c(
          "strong",
          [_c("simple-link", { attrs: { target: _vm.comment.userprofile } })],
          1
        ),
        _vm._v(" has linked\n        "),
        !_vm.blogAnswer.accessible
          ? _c("strong", [
              _vm._v(
                "Diary Log entry to this " +
                  _vm._s(_vm._f("customTerm")("Task")) +
                  ":"
              ),
            ])
          : _c("strong", [
              _c("a", { attrs: { href: _vm.blogAnswer.link } }, [
                _vm._v(
                  "Diary Log entry to this " +
                    _vm._s(_vm._f("customTerm")("Task")) +
                    ":"
                ),
              ]),
            ]),
        _vm._v(" "),
        _c("br"),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("br"),
        _vm._v(" "),
        _c(
          "render-quote",
          {
            attrs: {
              "limit-size": true,
              "quote-border-color": _vm.moveAvatarToRight ? "#fff" : undefined,
            },
          },
          [
            _vm.questionPreview
              ? _c("strong", [_vm._v(_vm._s(_vm.questionPreview))])
              : _vm._e(),
            _vm._v(" "),
            _c("diary-location-answer", {
              attrs: { answer: _vm.blogAnswer.data },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }