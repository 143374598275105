/**
 * Created by orion on 29.05.17.
 */
GoalMainOptionsPopupController.$inject = ['$scope', '$uibModalInstance', '$timeout', '$log', 'initial_data', 'popup_config'];

function GoalMainOptionsPopupController($scope, $uibModalInstance, $timeout, $log, initial_data, popup_config) {
    $scope.close = function () {
        $uibModalInstance.dismiss('closed');
    };
    $scope.name = initial_data.name;
    $scope.description = initial_data.description;
    $scope.avatar = initial_data.uses_default_avatar ? undefined : initial_data.avatar;
    $scope.audio_caption = initial_data.audio_caption;

    $scope.for_user = initial_data.for_user;
    $scope.username = initial_data.username;

    $scope.popup_config = popup_config; // allow_create_for_user; users_list, allow_clone
    $scope.hide_tasks = initial_data.hide_tasks;

    $scope.available_categories = $scope.popup_config.available_categories.map(c => {
        const modifiedCategory = Object.assign({}, c);
        if (c.is_global) {
            modifiedCategory.name += ' (global)';
        }
        return modifiedCategory;
    });

    $scope.getCategoryName = (cat) => {
        if (cat.name === 'Default') {
            cat.name = 'Select Category/Vision';
        }
        if (cat.is_global) {
            return cat.name + ' (global)';
        } else {
            return cat.name;
        }
    };

    $scope.goal_category = initial_data.category;


    $scope.create = create;
    $scope.add_avatar = add_avatar;
    $scope.add_audio = add_audio;
    $scope.reset_avatar = reset_avatar;
    $scope.reset_audio_caption = reset_audio_caption;
    $scope.load_another_goal = load_another_goal;
    $scope.delete_goal = delete_goal;

    function dump_data() {
        return {
            name: $scope.name,
            description: $scope.description,
            avatar: $scope.avatar,
            audio_caption: $scope.audio_caption,
            hide_tasks: $scope.hide_tasks,
            category: $scope.goal_category,

            for_user: $scope.for_user,
            username: $scope.username,
        };
    }

    function create() {
        $uibModalInstance.close({
            type: 'save',
            data: dump_data()
        });
    }

    function add_avatar() {
        $uibModalInstance.close({
            type: 'add_avatar',
            data: dump_data()
        });
    }

    function load_another_goal() {
        $uibModalInstance.close({
            type: 'load_goal',
            data: dump_data()
        });
    }

    function add_audio() {
        $uibModalInstance.close({
            type: 'add_audio',
            data: dump_data()
        });
    }

    function delete_goal() {
        $uibModalInstance.close({
            type: 'delete_goal',
            data: dump_data()
        });
    }

    function reset_avatar() {
        $scope.avatar = undefined;
    }

    function reset_audio_caption() {
        $scope.audio_caption = undefined;
    }
}

export default GoalMainOptionsPopupController;