import GoalMainOptionsPopupController from './optionsPopup/controller';
import {customTermFilter} from "../../../../vue/filters/CustomTerm";
import selectFactory from "shared/select_factory/select_factory";
import CreateGoalTypeSelectorPopup from "./CreateGoalTypeSelectorPopup";

goalSettingsFactory.$inject = ['simplePopupFactory', '$q', 'addMedia', '$rootScope', 'GoalResource'];

function goalSettingsFactory(simplePopupFactory, $q, addMedia, $rootScope, GoalResource) {

    let DEFAULT_GOAL_DATA = {
        name: '',
        description: '',
        audio_caption: undefined,
        avatar: undefined,
        uses_default_avatar: false,
        hide_tasks: false,
        for_user: false,
        username: undefined,
        category: null
    };

    let goalToCopyId;
    let loadedGoalData = {};
    let availableCategories = [];

    function show_add_media_menu(type, default_callback, cancel_callback) {
        addMedia.add_media(
            {
                allow_add_from_library: true,
                max_count: 1,
                type: type,
                popup_header: 'What sort of media would you like to add?'
            },
            {
                default_callback,
                cancel_callback
            }
        );
    }

    function create_goal(initial, popup_config = {}) {
        loadedGoalData = {};
        let createResult = $q.defer();
        $rootScope.show_dimmer();
        const categoriesFilter = {};
        if (initial.for_user) {
            categoriesFilter['username'] = initial.username;
        }
        GoalResource.get_available_categories(categoriesFilter).then(resp => {
            availableCategories = resp.data;
            $rootScope.hide_dimmer();
            main_options(initial, popup_config, createResult);
        });
        return createResult.promise;
    }

    function edit_goal(initial, popup_config = {}) {
        loadedGoalData = {};
        goalToCopyId = undefined;
        let editResult = $q.defer();
        $rootScope.show_dimmer();
        GoalResource.get_available_categories({username: initial.owner.name}).then(resp => {
            availableCategories = resp.data;
            $rootScope.hide_dimmer();
            main_options(initial, popup_config, editResult);
        });
        return editResult.promise;
    }

    function show_copy_goal_menu() {
        let res = $q.defer();
        selectFactory().endpoint_selector(GoalResource.get_goals_to_copy, false, {
            header: `Select ${customTermFilter('Goal')} to copy data`,
            info: `Select ${customTermFilter('Goal')}
                   from which you want to copy data and ${customTermFilter('Tasks')}.`,
            button_yes: 'Select',
            button_no: 'Cancel',
            empty: `You do not have any ${customTermFilter('Goal')} to select.`,
            no_border_radius_on_default_avatar: true,
        }, false)
            .then(goalId => {
                $rootScope.show_dimmer();
                return GoalResource.get_goal_data_to_copy(goalId);
            }, err => {
                res.reject();
                return new Promise(() => {
                });
            })
            .then(resp => {
                res.resolve(resp.data);
            }, err => {
                notifications.error(err || 'Error');
                res.reject();
                return new Promise(() => {
                });
            })
            .finally($rootScope.hide_dimmer);
        return res.promise;
    }

    function main_options(initial, popup_config = {}, promise_to_resolve) {
        /**
         * @param initial {Object}
         * {name:string,
         * description: string,
         * avatar: MediaContent
         * audio_caption: MediaContent
         * hide_tasks: Boolean
         * for_user: Boolean
         * username: String
         * }
         * @return {Object} as above but filled\changed
         */
        let DEFAULT_POPUP_CONFIG = {
            header: `Create New ${customTermFilter('Goal')}`,
            save_button: 'Create',
            cancel_button: 'Cancel',
            additional_info: undefined,
            allow_clone: false,
            allow_delete: false,
            available_categories: availableCategories
        };
        let actual_popup_config = _.merge({}, DEFAULT_POPUP_CONFIG, popup_config);
        let initial_data = _.merge({}, DEFAULT_GOAL_DATA, initial);
        let options_result = promise_to_resolve === undefined ? $q.defer() : promise_to_resolve;
        let popup_result = simplePopupFactory.create_popup(GoalMainOptionsPopupController, require('./optionsPopup/template.html'), {
            initial_data,
            popup_config: actual_popup_config
        });

        let add_avatar = (items_list) => {
            initial_data.avatar = items_list[0];
            main_options(initial_data, popup_config, options_result);
        };

        let add_audio = (items_list) => {
            initial_data.audio_caption = items_list[0];
            main_options(initial_data, popup_config, options_result);
        };


        popup_result.then(res => {
            if (res.type === 'add_avatar') {
                initial_data = res.data;
                show_add_media_menu('image', add_avatar, () => {
                    main_options(initial_data, popup_config, options_result);
                });
            }
            if (res.type === 'add_audio') {
                initial_data = res.data;
                show_add_media_menu('audio', add_audio, () => {
                    main_options(initial_data, popup_config, options_result);
                });
            }
            if (res.type === 'delete_goal') {
                options_result.resolve({result_type: 'delete_goal'});
            }
            if (res.type === 'save') {
                let data_to_resolve = res.data;
                if (!res.data.avatar) {
                    data_to_resolve.reset_avatar = true;
                }
                if (!res.data.audio_caption) {
                    data_to_resolve.reset_audio_caption = true;
                }
                if (res.data.avatar) {
                    data_to_resolve.media_for_avatar_id = res.data.avatar.pk;
                }
                if (res.data.audio_caption) {
                    data_to_resolve.media_for_audio_caption_id = res.data.audio_caption.pk;
                }
                if (goalToCopyId) {
                    data_to_resolve.goal_to_copy = goalToCopyId;
                }
                options_result.resolve({result_type: 'goal_data', goal_data: data_to_resolve});
                loadedGoalData = {};
                goalToCopyId = undefined;
            }
            if (res.type === 'load_goal') {
                show_copy_goal_menu()
                    .then(goalData => {
                        loadedGoalData = goalData;
                        goalToCopyId = goalData.id;
                        main_options(_.merge(initial_data, loadedGoalData), popup_config, options_result);
                    }, err => {
                        main_options(initial_data, popup_config, options_result);
                    });
            }
        }, rej => {
            options_result.reject('closed');
        });

        return options_result.promise;
    }

    async function create_with_select_type_of_creation(initial, popup_config = {}) {
        goalToCopyId = undefined;
        const {popupComponent, vm} = simplePopupFactory.mount_vue_popup(CreateGoalTypeSelectorPopup);
        const selected = await popupComponent.show().catch(() => {
        });
        vm.$destroy();
        if (!selected) {
            return;
        }
        if (selected === 'copy') {
            const data = await show_copy_goal_menu();
            goalToCopyId = data.id;
            return create_goal(Object.assign({}, initial, data, {category: initial.category || data.category}), popup_config);
        } else {
            return create_goal(initial, popup_config);
        }
    }

    return {create_goal: create_with_select_type_of_creation, edit_goal};
}


export default goalSettingsFactory;