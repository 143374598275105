<template>
    <div class="">
        <goal-detail-page-header v-if="initialized"></goal-detail-page-header>
        <div class="">
            <router-view
                    v-if="initialized||error"
                    @userSelfDeleted="userSelfDeleted"
            ></router-view>
        </div>
    </div>
</template>

<script>
    import {DjangoUrls} from 'DjangoUrls';
    import Vue from 'vue';
    import goalDetailPageStore from "./store";
    import GoalDetailPageHeader from "./GoalDetailPageHeader/GoalDetailPageHeader";
    import {CurrentUserClass} from "shared/CurrentUser";
    import {GOAL_HEADER_BUS_ACTIONS, goalHeaderBus} from "./GoalDetailPageHeader/goalHeaderBus";
    import BackButtonMixin from "../../../../../vue/mixins/BackButtonMixin";
    import {customTermFilter} from "../../../../../vue/filters/CustomTerm";

    export default {
        name: "GoalDetailPageMain",
        components: {GoalDetailPageHeader},
        mixins: [BackButtonMixin],
        data() {
            return {
                initialized: false,
                error: false,
            };
        },
        computed: {
            goal() {
                return this.$store.state.goalDetailPageStore.goal;
            },
            defaultBackUrl() {
                return DjangoUrls['projects']();
            }
        },
        methods: {
            $_initGoal() {
                const GoalResource = Vue.getAngularModule('GoalResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                $rootScope.show_dimmer();
                GoalResource.get_goal_detail_data(this.$route.params.goalId)
                    .then(({data}) => {
                        this.$store.commit('goalDetailPageStore/set_is_admin', data.is_admin);
                        this.$store.commit('goalDetailPageStore/set_is_member', data.is_member);
                        this.$store.commit('goalDetailPageStore/set_join_request_pending', data.is_join_request_pending);
                        this.$store.commit('goalDetailPageStore/set_goal', data.goal);
                        this.$store.commit('goalDetailPageStore/set_members', data.members);
                        this.$store.commit('goalDetailPageStore/set_current_user', new CurrentUserClass());
                        if (data.is_member) {
                            this.$_fetchTasks();
                        } else {
                            this.$router.replace({
                                name: 'GoalDetailPageMainAbout',
                                query: this.$route.query,
                                hash: this.$route.hash
                            });
                            this.initialized = true;
                            $rootScope.hide_dimmer();
                        }

                    }, err => {
                        if (err.status === 404) {
                            this.$router.replace({'name': '404'});
                            this.error = true;
                        }
                        if (err.status === 403) {
                            this.$router.replace({'name': '403'});
                            this.error = true;
                        }
                        $rootScope.hide_dimmer();
                    });
            },
            $_fetchTasks() {
                this.$store.dispatch('goalDetailPageStore/load_tasks')
                    .then(() => {
                        this.$nextTick(() => {
                            this.initialized = true;
                        });
                    });
            },
            goBack() {
                this.$_goBack();
            },
            userSelfDeleted() {
                this.goBack();
            },
            editGoal() {
                let resultType;
                const goalSettingsFactory = Vue.getAngularModule('goalSettingsFactory');
                const simplePopupFactory = Vue.getAngularModule('simplePopupFactory');
                const GoalResource = Vue.getAngularModule('GoalResource');
                const $rootScope = Vue.getAngularModule('$rootScope');
                const $q = Vue.getAngularModule('$q');
                goalSettingsFactory.edit_goal(this.goal, {
                    header: `Edit ${customTermFilter('Goal')}`,
                    save_button: 'Save',
                    allow_delete: true
                })
                    .then(result => {
                        resultType = result.result_type;
                        if (resultType === 'delete_goal') {
                            return simplePopupFactory.show_popup(`Delete ${customTermFilter('Goal')}`, `Are you sure you want to delete the ${customTermFilter('Goal')} ${this.goal.name} and all its contents?`, 'Delete', 'Cancel', 'btn-red')
                                .then(y => {
                                    $rootScope.show_dimmer();
                                    return GoalResource.delete_goal(this.goal.id);
                                }, n => {
                                    return $q.defer().promise;
                                });
                        }
                        if (resultType === 'goal_data') {
                            $rootScope.show_dimmer();
                            return GoalResource.update_main_options(this.goal.id, result.goal_data);
                        }
                    }, () => new Promise(()=>{}))
                    .then(resp => {
                        if (resultType === 'goal_data') {
                            this.$store.commit('goalDetailPageStore/set_goal', resp.data);
                            Vue.notifications.success('Updated');
                            $rootScope.hide_dimmer();
                        } else {
                            Vue.notifications.success('Deleted');
                            this.goBack();
                        }
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                        $rootScope.hide_dimmer();
                    });
            }
        },
        beforeCreate() {
            this.$store.registerModule('goalDetailPageStore', goalDetailPageStore);
        },
        mounted() {
            this.$_initGoal();
            goalHeaderBus.$on(GOAL_HEADER_BUS_ACTIONS.GO_BACK, this.goBack);
            goalHeaderBus.$on(GOAL_HEADER_BUS_ACTIONS.EDIT_GOAL, this.editGoal);
        },
        beforeDestroy() {
            goalHeaderBus.$off(GOAL_HEADER_BUS_ACTIONS.GO_BACK, this.goBack);
            goalHeaderBus.$off(GOAL_HEADER_BUS_ACTIONS.EDIT_GOAL, this.editGoal);
            this.$store.unregisterModule('goalDetailPageStore');
        },
        metaInfo() {
            const goalTerm = this.$customTerm('Goal');
            const title = `${this.goal ? this.$options.filters.cutName(this.goal.name, 20) + ' - ' : ''}${goalTerm}`;
            return {
                title,
            };
        }
    };
</script>

<style scoped>

</style>