/**
 * Created by orion on 28.05.17.
 */
import urls from 'DjangoUrls';
import _ from 'lodash';

let {DjangoUrls} = urls;
GoalResource.$inject = ['$http'];

function GoalResource($http) {
    return {
        get_my_goals: function (params) {
            const url = DjangoUrls["api.v1:goals-get-my-goals"]();
            return $http({
                method: 'GET',
                url,
                params,
                cache: false
            });
        },
        get_my_goals_filters_data: function (params) {
            const url = DjangoUrls["api.v1:goals-get-my-goals-filters-data"]();
            return $http({
                method: 'GET',
                url,
                params,
                cache: false
            });
        },
        get_available_categories: function (params) {
            const url = DjangoUrls["api.v1:goals-get-available-categories"]();
            return $http({
                method: 'GET',
                url,
                params
            });
        },

        get_goals_to_copy_task: function (params) {
            const url = DjangoUrls["api.v1:goals-get-goals-to-copy-task"]();
            return $http({
                method: 'GET',
                url,
                params,
                cache: false
            });
        },

        get_available_assistance: function (params) {
            const url = DjangoUrls["api.v1:goals-get-available-assistance"]();
            return $http({
                method: 'GET',
                url,
                params,
                cache: false
            });
        },

        get_goals_to_copy: function (params) {
            const url = DjangoUrls["api.v1:goals-get-goals-to-copy"]();
            return $http({
                method: 'GET',
                url,
                params,
                cache: false
            });
        },

        get_actions_to_copy: function (goalId, params) {
            const url = DjangoUrls["api.v1:goals-get-actions-to-copy"](goalId);
            return $http({
                method: 'GET',
                url,
                params,
                cache: false
            });
        },

        get_task_data_to_copy: function (goalId, taskId) {
            const url = DjangoUrls["api.v1:goals-get-task-data-to-copy"](goalId);
            return $http({
                method: 'POST',
                url,
                data: {id: taskId}
            });
        },

        get_goal_data_to_copy: function (goalId) {
            const url = DjangoUrls["api.v1:goals-get-goal-data-to-copy"](goalId);
            return $http({
                method: 'POST',
                url
            });
        },

        get_goal_detail_data: function (goalId) {
            const url = DjangoUrls["api.v1:goals-get-goal-detail-data"](goalId);
            return $http({
                method: 'GET',
                url,
            });
        },

        create_goal: function (data) {
            const url = DjangoUrls["api.v1:goals-create-goal"]();
            return $http({
                method: 'POST',
                url,
                data
            });
        },

        join_goal: function (id) {
            const url = DjangoUrls["api.v1:goals-join-goal"](id);
            return $http({
                method: 'POST',
                url
            });
        },

        delete_member: function (goal_id, user_id, reset_or_delete) {
            const url = DjangoUrls["api.v1:goals-delete-member"](goal_id);
            return $http({
                method: 'POST',
                url,
                data: {
                    id: user_id,
                    mode: reset_or_delete
                }
            });
        },

        update_admins: function (goal_id, new_checked_ids, new_unchecked_ids) {
            const url = DjangoUrls["api.v1:goals-update-admins"](goal_id);
            return $http({
                method: 'POST',
                url,
                data: {
                    new_checked_ids, new_unchecked_ids
                }
            });
        },

        update_main_options: function (goal_id, data) {
            const url = DjangoUrls["api.v1:goals-update-main-options"](goal_id);
            return $http({
                method: 'POST',
                url,
                data
            });
        },

        reorder_tasks: function (goal_id, ordered_pk_list) {
            const url = DjangoUrls["api.v1:goals-reorder-tasks"](goal_id);
            return $http({
                method: 'POST',
                url,
                data: {ordered_pk_list}
            });
        },

        invite_users: function (goal_id, new_checked_ids, new_unchecked_ids) {
            const url = DjangoUrls["api.v1:goals-invite-users"](goal_id);
            return $http({
                method: 'POST',
                url,
                data: {
                    new_checked_ids, new_unchecked_ids
                }
            });
        },

        delete_goal: function (goal_id) {
            const url = DjangoUrls["api.v1:goals-delete-goal"](goal_id);
            return $http({
                method: 'DELETE',
                url
            });
        },
        make_me_admin: function (goal_id) {
            const url = DjangoUrls["api.v1:goals-make-me-admin"](goal_id);
            return $http({
                method: 'POST',
                url
            });
        }

    };
}

export default GoalResource;