import EndpointSelectorPopup from './endpoint_selector_popup/EndpointSelectorPopup';
import {simplePopupFactory} from '../../shared/simple_popups/simplePopupFactory';

selectFactory.$inject = [];

function selectFactory() {

    return {
        endpoint_selector: endpoint_selector_new
    };

    function endpoint_selector_new(page_query_endpoint, allow_multiple = false, config = {}, allow_null = false, allow_all = false, return_object = false) {
        /**
         * @param page_query_endpoint: {string} should support args: [page, query].
         * should return {results:[{id:integer, avatar:url, name: string}]}
         * @param config:object - for header, buttons, text
         * @returns {integer} or {array}<array> - list of checked and unchecked ids.
         * @example
         * 1
         * or
         * [[1,2],[4,7]]
         * or
         * [[],[],true] in case of all checked. allow_all = true
         */
        const {vm, popupComponent, fEl} = simplePopupFactory.mount_vue_popup(EndpointSelectorPopup);
        return new Promise((resolve, reject) => {
            popupComponent.show(page_query_endpoint, allow_multiple, config, allow_null, allow_all, return_object)
                .then(res => {
                    resolve(res);
                }, rej => {
                    reject(rej);
                })
                .finally(() => {
                    fEl?.focus();
                    vm?.$destroy();
                });
        });
    }
}

export default selectFactory;