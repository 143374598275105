/**
 * Created by orion on 09.05.17.
 */
import urls from 'DjangoUrls';

let {DjangoUrls} = urls;
let _ = require('lodash');
GoalTaskResource.$inject = ['$http', '$log'];

function GoalTaskResource($http, $log) {
    const PREFIX = 'api.v1:goal_tasks-';
    return {
        get_goal_actions: function (id) {
            const url = DjangoUrls[`${PREFIX}get-goal-actions`]();
            return $http({
                method: 'GET',
                url,
                params: {id}
            });
        },
        create_task: function (project_id, data) {
            const url = DjangoUrls[`${PREFIX}create-task`]();
            let data_for_create = _.clone(data);
            data_for_create.project_id = project_id;
            return $http({
                method: 'POST',
                url,
                data: data_for_create
            });
        },
        update_main_options: function (task_id, data) {
            const url = DjangoUrls[`${PREFIX}update-main-options`](task_id);
            return $http({
                method: 'POST',
                url,
                data
            });
        },

        update_date_options: function (task_id, data) {
            const url = DjangoUrls[`${PREFIX}update-date-options`](task_id);
            return $http({
                method: 'POST',
                url,
                data
            });
        },

        mark_task_as_completed: function (task_id, with_assistance_params = false, provided_assistance = undefined) {
            const url = DjangoUrls[`${PREFIX}mark-task-as-completed`](task_id);
            return $http({
                method: 'POST',
                url,
                data: {
                    with_assistance_params,
                    provided_assistance,
                }
            });
        },

        update_task_completion_params: function (task_id, with_assistance_params = false, provided_assistance = undefined) {
            const url = DjangoUrls[`${PREFIX}update-task-completion-params`](task_id);
            return $http({
                method: 'POST',
                url,
                data: {
                    with_assistance_params,
                    provided_assistance,
                }
            });
        },

        mark_task_as_incompleted: function (task_id) {
            const url = DjangoUrls[`${PREFIX}mark-task-as-incompleted`](task_id);
            return $http({
                method: 'POST',
                url
            });
        },

        mark_step_as_completed: function (task_id, step_id, with_assistance_params = false, provided_assistance = undefined) {
            const url = DjangoUrls[`${PREFIX}mark-step-as-completed`](task_id);
            return $http({
                method: 'POST',
                url,
                data: {
                    id: step_id,
                    with_assistance_params,
                    provided_assistance,
                }
            });
        },

        mark_step_as_incompleted: function (task_id, step_id) {
            const url = DjangoUrls[`${PREFIX}mark-step-as-incompleted`](task_id);
            return $http({
                method: 'POST',
                url,
                data: {id: step_id}
            });
        },

        update_step_completion_params: function (task_id, step_id, with_assistance_params = false, provided_assistance = undefined) {
            const url = DjangoUrls[`${PREFIX}update-step-completion-params`](task_id);
            return $http({
                method: 'POST',
                url,
                data: {
                    id: step_id,
                    with_assistance_params,
                    provided_assistance,
                }
            });
        },

        update_step_data(task_id, step_id, data) {
            const url = DjangoUrls[`${PREFIX}update-step-data`](task_id);
            return $http({
                method: 'POST',
                url,
                data: Object.assign({id: step_id}, data)
            });
        },

        create_step(task_id, data) {
            const url = DjangoUrls[`${PREFIX}create-step`](task_id);
            return $http({
                method: 'POST',
                url,
                data,
            });
        },

        remove_step(task_id, step_id) {
            const url = DjangoUrls[`${PREFIX}remove-step`](task_id);
            return $http({
                method: 'POST',
                url,
                data: {id: step_id},
            });
        },

        update_assignee_options: function (task_id, data) {
            const url = DjangoUrls[`${PREFIX}update-assignee-options`](task_id);
            return $http({
                method: 'POST',
                url,
                data
            });
        },

        clone_for_multiple_assignees: function (task_id, id_list, event_data = {}) { // {from_event:Number, event_date: 'DD/MM/YYYY'}
            const url = DjangoUrls[`${PREFIX}clone-for-multiple-assignees`](task_id);
            return $http({
                method: 'POST',
                url,
                data: Object.assign({id_list}, event_data)
            });
        },

        update_date_and_assignee_timeline: function (task_id, data) {
            const url = DjangoUrls[`${PREFIX}update-date-and-assignee-timeline`](task_id);
            return $http({
                method: 'POST',
                url,
                data
            });
        },
        accept_assignment: function (task_id,) {
            const url = DjangoUrls[`${PREFIX}accept-assignment`](task_id);
            return $http({
                method: 'POST',
                url
            });
        },

        reject_assignment: function (task_id,) {
            const url = DjangoUrls[`${PREFIX}reject-assignment`](task_id);
            return $http({
                method: 'POST',
                url
            });
        },

        change_task_days_count: function (task_id, data) {
            const url = DjangoUrls[`${PREFIX}change-task-days-count`](task_id);
            return $http({
                method: 'POST',
                url,
                data
            });
        },

        change_access_code_settings: function (task_id, enabled) {
            const url = DjangoUrls[`${PREFIX}change-access-code-settings`](task_id);
            return $http({
                method: 'PATCH',
                url,
                data: {enabled}
            });
        },

        regenerate_access_code: function (task_id) {
            const url = DjangoUrls[`${PREFIX}regenerate-access-code`](task_id);
            return $http({
                method: 'PATCH',
                url
            });
        },

        invite_by_email: function (task_id, data) {
            const url = DjangoUrls[`${PREFIX}invite-by-email`](task_id);
            return $http({
                method: 'POST',
                url,
                data
            });
        },

        delete_task: function (task_id) {
            const url = DjangoUrls[`${PREFIX}delete-task`](task_id);
            return $http({
                method: 'DELETE',
                url
            });
        },


    };
}

export default GoalTaskResource;