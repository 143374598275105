import Vue from 'vue';
import Vuex from 'vuex';
import _ from "lodash";
import {goalHeaderBus, GOAL_HEADER_BUS_ACTIONS} from './goalHeaderBus.js';
import {taskActionsBus, TASK_ACTION_BUS_ACTIONS} from '../../taskActionsBus';
import config from "../../config";
import {customTermFilter} from "../../../../../vue/filters/CustomTerm";

Vue.use(Vuex);

function isEditModePossible(isAdmin, tasks, currentUserId) {
    if (isAdmin) {
        return true;
    }
    for (let task of tasks) {
        if (task.created_by.id === currentUserId) {
            return true;
        }
    }
    return false;
}

const store = {
    namespaced: true,
    state() {
        return {
            tasks: [],
            members: [],
            goal: {},
            isMember: false,
            isAdmin: false,
            editMode: false,
            joinRequestPending: false,
            currentUser: {},
            tagsInFilter: [],
            assigneesInFilter: [],
            activeUrl: undefined,
            currentlyDraggedTask: undefined,
            currentlyUnderDragTask: undefined,
            showOtherUsersTasks: false,
        };
    },
    mutations: {
        set_show_other_users_tasks(state, val) {
            state.showOtherUsersTasks = val;
        },
        set_join_request_pending(state, val) {
            state.joinRequestPending = val;
        },
        set_currently_dragged_task(state, task) {
            state.currentlyDraggedTask = task;
        },
        reset_currently_dragged_task(state) {
            state.currentlyDraggedTask = undefined;
        },
        set_currently_under_drag_task(state, task) {
            state.currentlyUnderDragTask = task;
        },
        reset_currently_under_drag_task(state) {
            state.currentlyUnderDragTask = undefined;
        },
        drop_task(state, {taskToMove, targetTask}) {
            const taskToMoveIndex = state.tasks.indexOf(taskToMove);
            const targetTaskIndex = state.tasks.indexOf(targetTask);
            const correction = taskToMoveIndex > targetTaskIndex ? 0 : 1;
            state.tasks.splice(taskToMoveIndex, 1);
            const newTargetTaskIndex = state.tasks.indexOf(targetTask);
            state.tasks.splice(newTargetTaskIndex + correction, 0, taskToMove);
            state.currentlyDraggedTask = undefined;
            state.currentlyUnderDragTask = undefined;
        },
        set_active_url(state, url) {
            state.activeUrl = url;
        },
        delete_task(state, task) {
            state.tasks.splice(state.tasks.indexOf(task), 1);
        },
        add_task(state, task) {
            state.tasks.unshift(task);
        },
        update_task(state, updatedTask) {
            const index = _.findIndex(state.tasks, t => t.id === updatedTask.id);
            Vue.set(state.tasks, index, updatedTask);
        },
        toggle_filter_by_tag(state, tag) {
            if (state.tagsInFilter.indexOf(tag) === -1) {
                state.tagsInFilter.push(tag);
            } else {
                state.tagsInFilter.splice(state.tagsInFilter.indexOf(tag), 1);
            }
            taskActionsBus.$emit(TASK_ACTION_BUS_ACTIONS.SET_TAGS_IN_FILTER, state.tagsInFilter);
        },
        set_tags_in_filter(state, value) {
            state.tagsInFilter = value;
        },
        set_current_user(state, value) {
            state.currentUser = value;
        },
        set_members(store, members) {
            store.members = [];
            store.members = members.map(m => m);
        },
        set_edit_mode(state, {value, emitEvent}) {
            state.editMode = value;
            if (emitEvent) {
                goalHeaderBus.$emit(GOAL_HEADER_BUS_ACTIONS.TOGGLE_EDIT_MODE, value);
            }
        },
        set_is_admin(state, isAdmin) {
            state.isAdmin = isAdmin;
            if (!isAdmin) {
                state.editMode = false;
            }
        },
        update_members_admin_status(state, {newAdmins, noMoreAdmins}) {
            for (let i = 0; i < state.members.length; i++) {
                let member = state.members[i];
                if (newAdmins && newAdmins.indexOf(member.id) !== -1) {
                    member.is_admin = true;
                }
                if (noMoreAdmins && noMoreAdmins.indexOf(member.id) !== -1) {
                    member.is_admin = false;
                }
            }
        },
        delete_member(state, {member, mode}) {
            state.members.splice(state.members.indexOf(member), 1);
            if (mode === 'delete') {
                state.tasks = state.tasks.filter(t => (!t.assignee || t.assignee.id !== member.id));
            }
            if (mode === 'reset') {
                for (let task of state.tasks) {
                    if (task.assignee && task.assignee.id === member.id) {
                        task.assignee = undefined;
                    }
                }
            }
        },
        add_member(state, memberData) {
            state.members.push(memberData);
        },
        set_is_member(state, isMember) {
            state.isMember = isMember;
        },
        set_tasks(state, tasks) {
            state.tasks = tasks;
        },
        set_goal(state, goal) {
            state.goal = goal;
        },
        update_task_from_server_data(state, task) {
            let index_in_list = _.findIndex(state.tasks, t => t.id === task.id);
            Vue.set(state.tasks, index_in_list, task);
        },
        empty() {

        },
        set_assignees_in_filter(state, value) {
            state.assigneesInFilter = value['id_list'];
            let raise_event = true;
            if (value['raise_event'] !== undefined) {
                raise_event = value['raise_event'];
            }
            if (raise_event) {
                taskActionsBus.$emit(TASK_ACTION_BUS_ACTIONS.SET_ASSIGNEES_IN_FILTER, value['id_list']);
            }
        }
    },
    actions: {
        create_task({state, commit}, taskData) {
            Vue.getAngularModule('$rootScope').show_dimmer();
            Vue.getAngularModule('GoalTaskResource').create_task(state.goal.id, taskData)
                .then(resp => {
                    commit('add_task', resp.data.created_task);
                    if (resp.data.added_member) {
                        commit('add_member', resp.data.added_member);
                    }
                    notifications.success(`${customTermFilter('Task')} created`);
                }, err => {
                    notifications.error(err || 'Error');
                })
                .finally(Vue.getAngularModule('$rootScope').hide_dimmer);
        },
        drop_task({state, commit}) {
            const taskToMove = state.currentlyDraggedTask;
            const targetTask = state.currentlyUnderDragTask;
            commit('drop_task', {taskToMove, targetTask});
            const orderedIdList = state.tasks.map(t => t.id);
            Vue.getAngularModule('$rootScope').show_dimmer();
            Vue.getAngularModule('GoalResource').reorder_tasks(state.goal.id, orderedIdList)
                .then(resp => {
                    Vue.notifications.success('Reordered');
                }, err => {
                    Vue.notifications.success(err || 'Error');
                })
                .finally(() => {
                    Vue.getAngularModule('$rootScope').hide_dimmer();
                });
        },
        make_me_admin({state, commit, dispatch}) {
            Vue.getAngularModule('$rootScope').show_dimmer();
            Vue.getAngularModule('GoalResource').make_me_admin(state.goal.id)
                .then(resp => {
                    commit('set_is_admin', true);
                    commit('update_members_admin_status', {newAdmins: [Vue.getAngularModule('CurrentUser').id]});
                    dispatch('load_tasks');
                    Vue.notifications.success(`You are admin of this ${customTermFilter('Goal')} now.`);
                }, err => Vue.notifications.error(err || 'Error'))
                .finally(Vue.getAngularModule('$rootScope').hide_dimmer);
        },
        load_tasks({state, commit}) {
            const GoalTaskResource = Vue.getAngularModule('GoalTaskResource');
            const $rootScope = Vue.getAngularModule('$rootScope');
            $rootScope.show_dimmer();
            return GoalTaskResource.get_goal_actions(state.goal.id)
                .then(resp => {
                    commit('set_tasks', resp.data);
                    $rootScope.hide_dimmer();
                }, err => {
                    $rootScope.hide_dimmer();
                    Vue.notifications.error(err || 'Error');
                });
        }
    },
    getters: {
        flattenedTasks(state, getters) {
            let taskList = [];
            if (state.tasks && state.tasks.length) {
                for (let task of state.tasks) {
                    let taskCopy = Object.assign({}, task);
                    taskCopy.children = [];
                    taskList.push(taskCopy);
                    if (task.children && task.children.length) {
                        for (let taskChild of task.children) {
                            taskList.push(taskChild);
                        }
                    }
                }
            }

            return taskList;
        },
        completedPercentage(state, getters) {
            if (state.tasks.length === 0) {
                return 0;
            }
            let allTasksCount = 0;
            let completedTasksCount = 0;
            let completedByMeTasksCount = 0;

            for (let task of state.tasks) {
                allTasksCount++;
                if (task.status === 'completed') {
                    completedTasksCount++;
                    if (task.assignee && task.assignee.id === state.currentUser.id) {
                        completedByMeTasksCount++;
                    }
                }
                if (task.children && task.children.length) {
                    for (let child of task.children) {
                        allTasksCount++;
                        if (child.status === 'completed') {
                            completedTasksCount++;
                            if (child.assignee && child.assignee.id === state.currentUser.id) {
                                completedByMeTasksCount++;
                            }
                        }
                    }
                }

            }
            if (state.isAdmin) {
                return Math.round(100 * (completedTasksCount / allTasksCount));
            } else {
                return Math.round(100 * (completedByMeTasksCount / allTasksCount));
            }

        },
        tagsInProject(state, getters) {
            const tagsDict = {};
            for (let task of getters.flattenedTasks) {
                for (let tag of task.tags) {
                    if (tagsDict[tag]) {
                        tagsDict[tag]++;
                    } else {
                        tagsDict[tag] = 1;
                    }
                }
            }
            return _.orderBy(_.keys(tagsDict), key => tagsDict[key], 'desc');
        },
        isEditModePossible(state, getters) {
            return isEditModePossible(state.isAdmin, state.tasks, state.currentUser.id);
        },
        iCanMakeMeAdmin(state, getters) {
            return state.isMember && !state.isAdmin && Vue.getAngularModule('CurrentUser').is_guardian_of(state.goal.owner.id);
        },
        filterIsActive(state, getters) {
            return !!(state.assigneesInFilter.length || state.tagsInFilter.length);
        },
        visibleToMeTasks(state, getters) {
            const flattenTasks = getters.filterIsActive || (!state.showOtherUsersTasks && !state.isAdmin);

            let taskList;
            if (flattenTasks) {
                taskList = getters.flattenedTasks;
            } else {
                taskList = state.tasks;
            }

            let taskIsVisible = function (task) {
                if (state.assigneesInFilter.length > 0) {
                    if (task.assignee) {
                        return state.assigneesInFilter.indexOf(task.assignee.id) !== -1;
                    } else {
                        return state.assigneesInFilter.indexOf(config.EMPTY_ASSIGNEE_ID) !== -1;
                    }
                }
                if (state.isAdmin) {
                    return true;
                }
                if (state.showOtherUsersTasks) {
                    return true;
                }
                if (!task.assignee) {
                    return true;
                }
                if (task.created_by.id === state.currentUser.id) {
                    return true;
                }
                if (task.assignee.id === state.currentUser.id) {
                    return true;
                }
            };
            return taskList.filter(t => taskIsVisible(t));
        },
        availableAssignees(state, getters) {
            const assignees = [{
                'name': config.EMPTY_ASSIGNEE_NAME,
                'id': config.EMPTY_ASSIGNEE_ID,
                'avatar': config.EMPTY_ASSIGNEE_AVATAR
            }];
            for (let _task of getters.flattenedTasks) {
                if (_task.assignee && !assignees.filter(a => a.id === _task.assignee.id).length) {
                    assignees.push(_task.assignee);
                }
            }
            return assignees;
        }
    },
    modules: {
        // timeline
    }
};

export default store;