<template>
    <div class="chat-style-comment-linked-blog-answer">
        <span class="chat-style-comment-linked-blog-answer__text"
              :class="{'chat-style-comment-linked-blog-answer__text_white': moveAvatarToRight}">
            <strong>
                <simple-link :target="comment.userprofile"></simple-link>
            </strong> has linked
            <strong v-if="!blogAnswer.accessible">Diary Log entry to this {{ 'Task' | customTerm }}:</strong>
            <strong v-else>
                <a :href="blogAnswer.link">Diary Log entry to this {{ 'Task' | customTerm }}:</a>
            </strong>
            <br/>
        </span>
        <div>
            <br>
            <render-quote :limit-size="true" :quote-border-color="moveAvatarToRight?'#fff':undefined">
                <strong v-if="questionPreview">{{ questionPreview }}</strong>
                <diary-location-answer
                        :answer="blogAnswer.data"
                ></diary-location-answer>
            </render-quote>
        </div>
    </div>
</template>

<script>
    import DiaryLocationAnswer from "../../diary_page/DiaryLogAnswer";
    import RenderQuote from "shared/quote_directive/renderQuote";

    export default {
        name: "ChatStyleCommentLinkedBlogAnswer",
        components: {RenderQuote, DiaryLocationAnswer},
        props: {
            comment: Object,
            blogAnswer: Object,
            moveAvatarToRight: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            questionPreview() {
                return this.blogAnswer.data?.question?.text;
            }
        }
    };
</script>

<style scoped lang="scss">
  .chat-style-comment-linked-blog-answer {
    margin: 15px;

    &__text {
      font-size: 16px;

      &_white {
        color: #fff;

        &::v-deep {
          a {
            color: #fff !important;
          }
        }
      }
    }
  }
</style>