var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header-title__info" },
    [
      _c("div", {
        staticClass: "header-title__info__avatar",
        style: _vm.avatarStyle,
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "header-title__info__title" },
        [
          _vm._v("\n        " + _vm._s(_vm.goalTitle) + "\n        "),
          _vm.isTitleLargerThenPossible
            ? _c(
                "span",
                {
                  staticClass: "header-title__info__title__show-all",
                  on: { click: _vm.showWholeTitle },
                },
                [_vm._v("show all")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("audio-toggle", {
            attrs: { "audio-caption": _vm.goal.audio_caption },
          }),
          _vm._v(" "),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.editMode && _vm.isAdmin,
                expression: "editMode&&isAdmin",
              },
            ],
            staticClass: "fa fa-cog pointer edit-title-pencil",
            on: { click: _vm.editGoalMainParams },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "header-title__info__created" }, [
        _vm._v(
          "\n        " + _vm._s(_vm._f("customTerm")("Goal")) + " created by "
        ),
        _c("b", [
          _c("a", { attrs: { href: _vm.goal.created_by.link } }, [
            _vm._v(_vm._s(_vm.goal.created_by.name)),
          ]),
        ]),
        _vm._v(" "),
        _vm.goal.owner.id !== _vm.goal.created_by.id
          ? _c("span", [
              _vm._v("for "),
              _c("b", [
                _c("a", { attrs: { href: _vm.goal.owner.link } }, [
                  _vm._v(_vm._s(_vm.goal.owner.name)),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(
          "\n        on " +
            _vm._s(_vm._f("gbdate")(_vm.goal.created_at)) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _vm.isTitleLargerThenPossible
        ? _c(
            "modal",
            {
              attrs: { title: _vm.modalTitle, transition: 0, footer: false },
              model: {
                value: _vm.titleOpened,
                callback: function ($$v) {
                  _vm.titleOpened = $$v
                },
                expression: "titleOpened",
              },
            },
            [
              _c(
                "p",
                { staticClass: "goal-page-header__content-part__description" },
                [_vm._v(_vm._s(_vm.goal.name))]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }