<template>
    <div class="goals-list-page">
        <vue-simple-header v-if="initialized">
            <span slot="panelLeft">
                <span class="goals-list-page__page-title">{{ "Goals"|customTerm }}</span>
                <br>
                <span>
                    <router-link :to="{name:'myTasksListPageMain'}" style="color: #333;">{{
                            "Tasks"|customTerm
                        }}</router-link>
                </span>
            </span>
            <div slot="panelRight">
                <vue-folder-button :text=newGoalLabel icon-class="fa-plus" id="create-goal-button"
                                   @click.native="createGoal()"></vue-folder-button>
                <vue-folder-button text="Filter" icon-class="fa-filter" :active="filterIsActive"
                                   active-color="grass-green" id="filter-goal-button"
                                   @click.native="openFilterPopup"></vue-folder-button>

                <vue-search-input @search="search" @cancel="cancelSearch" id="search-through-goals"></vue-search-input>
            </div>
        </vue-simple-header>
        <div class="alert alert-info" v-show="noGoals">
            You do not have any {{ "Goals"|customTerm }} yet. Click on the New {{ "Goal"|customTerm }} button to make
            one!
        </div>
        <div class="alert alert-warning" v-show="nothingFound">No results.</div>
        <div class="" v-if="initialized">
            <div class="row">
                <div class="col-sm-12">
                    <a
                            href="#"
                            @click.stop.prevent="createGoalCategory"
                            class="goals-list-page__create-category-button"
                    ><span>+ New Category/Vision</span></a>
                </div>
            </div>
            <goals-list
                    :allow-create-goal="true"
                    :allow-archive-goal="true"
                    :categories="categories"
                    :available-categories="availableCategories"
                    :router-navigation="true"
                    @loadMore="loadMoreInCategory"
                    @archiveGoal="archiveGoal"
                    @unArchiveGoal="unArchiveGoal"
                    @createGoalInCategory="createGoalInCategory"
                    @editCategory="editGoalCategory"
            ></goals-list>
            <goal-list-page-filter
                    ref="filterPopup"
                    :owners-names="sharersNames"
                    :available-categories="categoriesForFilter"
                    :initial="filter"
                    @filterChanged="filterChanged"
            ></goal-list-page-filter>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import GoalsListPageMixin from "../../projects/GoalsListPageMixin";
    import GoalsList from "../../projects/goal_list_page/GoalsList";
    import GoalListPageFilter from "../../projects/goal_list_page/GoalListPageFilter";
    import goal_filter_settings_resource from "../../projects/goal_filter_settings_resource";
    import {CurrentUserClass} from "shared/CurrentUser";
    import GoalsListResource from "../../projects/project_list_resource";

    export default {
        name: "MyGoalsList",
        mixins: [GoalsListPageMixin],
        components: {
            GoalsList,
            GoalListPageFilter,
        },
        data() {
            return {
                dependantsNames: [],
                initialized: false,
            };
        },
        computed: {
            newGoalLabel() {
                return `New ${this.$customTerm('Goal')}`;
            }
        },
        methods: {
            getNewGoalPopupParams() {
                return {
                    allow_create_for_user: this.dependantsNames.length,
                    users_list: this.dependantsNames,
                    allow_clone: true
                };
            },
            getGoalInitialData(category = null) {
                return {category};
            },
            $_getMyGoalsFiltersData() {
                let GoalResource = Vue.getAngularModule('GoalResource');
                GoalResource.get_my_goals_filters_data()
                    .then(({data}) => {
                        this.dependantsNames = data.dependants_names;
                        this.sharersNames = data.sharers_names;
                        this.categoriesForFilter = data.categories_for_filter;
                        this.availableCategories = data.available_categories;
                        this.initialized = true;
                    }, err => {
                        Vue.notifications.error(err || 'Error');
                    });
            },
            async filterChanged(filterParams) {
                this.filter = filterParams;
                goal_filter_settings_resource.set_settings(new CurrentUserClass().id, filterParams);
                this.loadGoals();
            },
        },
        async mounted() {
            this.$_getMyGoalsFiltersData();
            let CurrentUser = new CurrentUserClass();
            this.targetUsersID = CurrentUser.id;
            this.goalsListMainResource = GoalsListResource().get_my_goals;
            this.loadMoreResource = GoalsListResource().load_more_in_category;
            await this.$_getMySavedFilterSettings();
            this.loadGoals();
        }
    };
</script>

<style scoped lang="scss">
  @import "styles/const.scss";

  .goals-list-page {
    &__page-title {
      font-size: 24px;
      font-weight: bold;
    }

    &__create-category-button {
      font-size: $bigger-font-size;
      margin-bottom: 10px;
      display: inline-block;
    }
  }
</style>