<template>
    <modal v-model="opened" :transition='0'>
        <div slot="header">
            <a id="close_popup" @click="close"></a>
            <h3 class="modal-title">Filter {{ "Goals"|customTerm }}</h3>
        </div>
        <div slot="footer">
            <button class="btn btn-mm btn-default" @click="reset">Reset Filter</button>
            <button class="btn btn-mm btn-default" @click="close">Cancel</button>
            <button class="btn btn-mm btn-green" @click="apply" :disabled="!show_my&&!show_shared">Apply</button>
        </div>
        <div class="row margin-15-top">
            <div class="col-sm-12">
                <div class="inline-block">
                    <vue-switch id="show_archived" name="show_archived" v-model="show_archived"
                                class="vertical-middle"></vue-switch>
                    <span class="vertical-middle"> Show Archived {{ "Goals"|customTerm }}</span>
                </div>
            </div>
        </div>
        <div class="row margin-15-top" v-show="allowMyFilters">
            <div class="col-sm-12">
                <div class="inline-block">
                    <vue-switch id="show_my" name="show_my" v-model="show_my"
                                class="vertical-middle"></vue-switch>
                    <span class="vertical-middle"> Show My {{ "Goals"|customTerm }}</span>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 15px;" v-show="allowMyFilters">
            <div class="col-sm-12">
                <div class="inline-block">
                    <vue-switch id="show_shared" name="show_shared" v-model="show_shared"
                                class="vertical-middle"></vue-switch>
                    <span class="vertical-middle"> Show Shared {{ "Goals"|customTerm }}</span>
                </div>
            </div>
        </div>
        <div class="row margin-15-top" v-if="usernameOptions.length">
            <div class="col-sm-12">
                <div>Filter by {{ "Goal"|customTerm }} Owner: <a href="javascript:;" class="pointer" @click="resetOwnerFilter"
                                              v-show="owners.length">(reset)</a></div>
                <multi-select :disabled="!show_shared" v-model="owners" :options="usernameOptions" size="lg" block></multi-select>
            </div>
        </div>
        <div class="row margin-15-top" v-if="categoryOptions.length>1">
            <div class="col-sm-12">
                <div>Filter by {{ "Goal"|customTerm }} Category: <a href="javascript:;" class="pointer" @click="resetCategoriesFilter"
                                                 v-show="categories.length">(reset)</a></div>
                <multi-select v-model="categories" :options="categoryOptions" size="lg" block></multi-select>
            </div>
        </div>
    </modal>
</template>

<script>
    import _ from 'lodash';

    export default {
        name: "GoalListPageFilter",
        props: {
            ownersNames: Array,
            availableCategories: Array,
            initial: Object,
            allowMyFilters: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                opened: false,
                owners: [],
                categories: [],
                show_archived: false,
                show_my: true,
                show_shared: false
            };
        },
        computed: {
            usernameOptions() {
                let res = [];
                for (let name of this.ownersNames) {
                    res.push({value: name, label: name});
                }
                return res;
            },
            categoryOptions() {
                let res = [];
                for (let category of this.availableCategories) {
                    res.push({value: category.id, label: category.name});
                }
                return res;
            }
        },
        methods: {
            dumpData() {
                return {
                    owners: this.show_shared ? this.owners : [],
                    categories: this.categories,
                    show_archived: this.show_archived,
                    show_my: this.show_my,
                    show_shared: this.show_shared
                };
            },
            backUpData() {
                this.backup = this.dumpData();
            },
            open() {
                _.merge(this, this.initial);
                this.backUpData();
                this.opened = true;
            },
            close() {
                this.opened = false;
                _.merge(this, this.backup);
            },
            resetOwnerFilter() {
                this.owners = [];
            },
            resetCategoriesFilter() {
                this.categories = [];
            },
            apply() {
                this.opened = false;
                this.$emit('filterChanged', this.dumpData());
            },
            reset() {
                this.opened = false;
                _.merge(this, this.initial);
                this.resetOwnerFilter();
                this.resetCategoriesFilter();
                this.$emit('filterChanged', this.dumpData());
            }
        },
        created() {
            _.merge(this, this.initial);
        }
    };
</script>

<style scoped>

</style>