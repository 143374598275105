var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goals-list-page" },
    [
      _vm.initialized
        ? _c("vue-simple-header", [
            _c("span", { attrs: { slot: "panelLeft" }, slot: "panelLeft" }, [
              _c("span", { staticClass: "goals-list-page__page-title" }, [
                _vm._v(_vm._s(_vm._f("customTerm")("Goals"))),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "span",
                [
                  _c(
                    "router-link",
                    {
                      staticStyle: { color: "#333" },
                      attrs: { to: { name: "myTasksListPageMain" } },
                    },
                    [_vm._v(_vm._s(_vm._f("customTerm")("Tasks")))]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { slot: "panelRight" }, slot: "panelRight" },
              [
                _c("vue-folder-button", {
                  attrs: {
                    text: _vm.newGoalLabel,
                    "icon-class": "fa-plus",
                    id: "create-goal-button",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.createGoal()
                    },
                  },
                }),
                _vm._v(" "),
                _c("vue-folder-button", {
                  attrs: {
                    text: "Filter",
                    "icon-class": "fa-filter",
                    active: _vm.filterIsActive,
                    "active-color": "grass-green",
                    id: "filter-goal-button",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.openFilterPopup.apply(null, arguments)
                    },
                  },
                }),
                _vm._v(" "),
                _c("vue-search-input", {
                  attrs: { id: "search-through-goals" },
                  on: { search: _vm.search, cancel: _vm.cancelSearch },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.noGoals,
              expression: "noGoals",
            },
          ],
          staticClass: "alert alert-info",
        },
        [
          _vm._v(
            "\n        You do not have any " +
              _vm._s(_vm._f("customTerm")("Goals")) +
              " yet. Click on the New " +
              _vm._s(_vm._f("customTerm")("Goal")) +
              " button to make\n        one!\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.nothingFound,
              expression: "nothingFound",
            },
          ],
          staticClass: "alert alert-warning",
        },
        [_vm._v("No results.")]
      ),
      _vm._v(" "),
      _vm.initialized
        ? _c(
            "div",
            {},
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12" }, [
                  _c(
                    "a",
                    {
                      staticClass: "goals-list-page__create-category-button",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.createGoalCategory.apply(null, arguments)
                        },
                      },
                    },
                    [_c("span", [_vm._v("+ New Category/Vision")])]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("goals-list", {
                attrs: {
                  "allow-create-goal": true,
                  "allow-archive-goal": true,
                  categories: _vm.categories,
                  "available-categories": _vm.availableCategories,
                  "router-navigation": true,
                },
                on: {
                  loadMore: _vm.loadMoreInCategory,
                  archiveGoal: _vm.archiveGoal,
                  unArchiveGoal: _vm.unArchiveGoal,
                  createGoalInCategory: _vm.createGoalInCategory,
                  editCategory: _vm.editGoalCategory,
                },
              }),
              _vm._v(" "),
              _c("goal-list-page-filter", {
                ref: "filterPopup",
                attrs: {
                  "owners-names": _vm.sharersNames,
                  "available-categories": _vm.categoriesForFilter,
                  initial: _vm.filter,
                },
                on: { filterChanged: _vm.filterChanged },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }